import React, {FC, lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./containers/login/Login";
import Loading from "./containers/Loading";
import AuthCallback from "./containers/authCallback/AuthCallback";
import {ISignedProps} from "./containers/SignedContainer";
import InvitationCallback from "./containers/invitationCallback/InvitationCallback";
import Redirector from "./tools/Redirector";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {AppConfig} from "./Config";

const SignedContainer: FC<ISignedProps> = lazy(() => import("./containers/SignedContainer"));

const App = () => {

	const [token, setToken] = useState<string | null>(localStorage.getItem('token'));

	useEffect(() => {
		const token = localStorage.getItem("token");
		const isInvitation = window.location.pathname.match(/\/invitation\/*/) !== null;
		if (isInvitation) {
			return;
		}
		if (token === null) {
			if (
				window.location.pathname !== "/" &&
				window.location.pathname !== "/google_backup/callback" &&
				!isInvitation
			) {
				Redirector.storeUri(window.location.pathname);
				window.location.href = "/";
			}
		}
		const evtListener = () => {
			const newToken = localStorage.getItem('token');
			if (newToken !== null && token === null) {
				setToken(newToken);
			} else {
				window.location.href = "/";
			}
		};
		window.addEventListener("storage", evtListener);
		return () => window.removeEventListener("storage", evtListener);
	}, []);

	return (
		<GoogleOAuthProvider clientId={AppConfig.getOauthClientId()}>
			<Suspense fallback={<Loading/>}>
				<BrowserRouter>
					<Routes>
						<Route path="/google_backup/callback" element={<AuthCallback/>}/>
						<Route path="/invitation/:code" element={<InvitationCallback/>}/>
						<Route path="/" element={<Login/>}/>
						{token === null ? null :
							<Route path="/*" key={"s_2"} element={<SignedContainer authToken={token}/>}/>
						}
					</Routes>
				</BrowserRouter>
			</Suspense>
		</GoogleOAuthProvider>
	);
};

export default App;
