import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import Loading from "../../containers/Loading";
import {deleteFromLocalStorage, doAuth, isValidInvitationCode, storeToLocalStorage} from "../../tools/Tools";
import Error from "./components/Error";
import Redirector from "../../tools/Redirector";

const baseUrl = Redirector.getUri();
const url: URL = new URL(window.location.href);
const code: string | null = url.searchParams.get('code');
const invitationCode: string | null = url.searchParams.get('state');
const errorCode: string | null = url.searchParams.get('error');
const errorParam: string | null = url.searchParams.get('error');



const AuthCallback: React.FC = () => {
	const [authenticated, setAuthenticated] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const title: string = code ? "Please wait" : "";
	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

	const makeAuthRequest = (code: string | null) => {

		if (code) {
			setError(null);
			let data: any = {
				"code": code,
				"agree": localStorage.getItem('agree')?.split(',')
			};

			if(isValidInvitationCode(invitationCode)) {
				data["invitation"] = invitationCode;
			}

			doAuth(data)
				.then((data: any) => {
					if (data && data.token) {
						deleteFromLocalStorage('agree');
						storeToLocalStorage('token', data.token);
						setAuthenticated(true);
					}
				})
				.catch(err => {
					setError(err.message);
				});
		} else if(errorParam || errorCode) {
			localStorage.clear();
			setRedirectToLogin(true);
		}
	};

	useEffect(() => {
		makeAuthRequest(code);
	}, []);

	if(authenticated) {
		return <Navigate to={baseUrl}/>;
	}

	if(redirectToLogin) {
		return <Navigate to={"/"}/>;
	}

	return (
		<>
			{error ? <Error error={error}/> : null}
			{!authenticated && !error ?
				<Loading col={5} title={title} showLogo={true}/> : null
			}
		</>
	);
};

export default AuthCallback;
