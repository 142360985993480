import React, {FC, ReactElement, useEffect, useRef, useState} from 'react';
import {Col, Input, Row} from 'antd';
import styles from '../scss/MFACodeInput.module.scss';

interface IAuthCodeInputProps {
	numInputs: number;
	onChange?: (value: string) => void;
	onSubmit?: (value: string) => void;
	value?: string;
	title?: string | ReactElement;
	description?: string | ReactElement;
	errorMessage?: string | null;
	hasError?: boolean;
	mask?: RegExp;
	position?: "left" | "center" | "right";
	autoSubmit?: boolean
}

const MFACodeInput: FC<IAuthCodeInputProps> = ({
   numInputs = 6,
   onChange,
   onSubmit,
   title = "MFA required",
   description = "Enter 6 digit code from your app",
   hasError = false,
   errorMessage = "",
   mask = /[0-9]/,
   position = "center",
   autoSubmit = false

}) => {

	const refs = Array.from({length: numInputs}).map(() => useRef<HTMLInputElement | null>(null));
	const [values, setValues] = useState<string[]>(Array(numInputs).fill(''));
	const lastIndex = refs.length - 1;
	const [error, setError] = useState<string | null>(errorMessage);


	useEffect(() => {
		if (refs[0].current) {
			refs[0].current!.focus();
		}
	}, []);

	useEffect(() => {
		setError(errorMessage);
	}, [errorMessage]);

	const updateValues = (newValues: string[], newIndex: number) => {
		setValues(newValues);
		if (newIndex >= 0 && newIndex <= lastIndex) {
			refs[newIndex].current!.focus();
		}
		onChange?.(newValues.join(''));
		if (error && error.length > 0) {
			setError("");
		}
		if (autoSubmit && isValidValues(newValues) && !showErrorMessage) {
			onSubmit?.(newValues.join(''));
		}
	};

	const isValidValues = (values: string[]): boolean => {
		return values.length === numInputs && values.every(value => mask.test(value));
	};

	const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>, index: number) => {
		evt.preventDefault();
		const pastedData = evt.clipboardData.getData('text').split('');
		const newValues = [...values];
		for (let i = 0; i < numInputs; i++) {
			const char = pastedData[i];
			newValues[i] = mask.test(char) ? char : "";
		}
		updateValues(newValues, lastIndex);
	};

	const handleOnKeyDown = (evt: React.KeyboardEvent, index: number) => {
		const {key} = evt;
		const newValues = [...values];
		if (key === 'ArrowLeft' || key === 'ArrowRight') {
			evt.preventDefault();
			const isLeft: boolean = key === 'ArrowLeft';
			const newIndex: number = isLeft ? index - 1 : index + 1;
			if (newIndex < 0 || newIndex > refs.length - 1) return;
			refs[newIndex].current!.focus();
		} else if (key === 'Backspace' || mask.test(key)) {
			evt.preventDefault();
			const isBackspace = key === 'Backspace';
			newValues[index] = isBackspace ? '' : key;
			const newIndex = isBackspace ? index - 1 : index + 1;
			updateValues(newValues, newIndex);
		}
	};

	const showErrorMessage: boolean = error !== null && error?.length > 0;

	return (
		<div className={styles.MFACodeInput}>
			<Row gutter={16} justify={position as any}>
				{title ? <Col xs={24}><div style={{textAlign: position as any}}>{title}</div></Col> : null}
				{refs.map((ref, index) => (
					<Col key={index} span={Math.round(24 / numInputs)}>
						<Input
							status={showErrorMessage ? "error" : ""}
							rootClassName={styles.customCaret}
							ref={ref}
							size={"large"}
							maxLength={1}
							value={values[index]}
							onPaste={(e) => handlePaste(e, index)}
							onKeyDown={(e) => handleOnKeyDown(e, index)}
							placeholder={"_"}
							style={{textAlign: "center"}}
						/>
					</Col>
				))}
			</Row>
			{description && !showErrorMessage ? <div className={styles.description}>{description}</div> : null}
			{showErrorMessage ? <div className={styles.error}>{error}</div> : null}
		</div>
	);
};

export default MFACodeInput;
