import data from "./config.json";

export interface IGoogleClientConfig {
	[type: string]: {
		clientId: string;
		scopes: string[];
		apiKey?: string;
		redirectUri?: string;
		reconnectUri?: string;
	};
}

export interface IGoogleConfig {
	clients: IGoogleClientConfig,
	scopes: string[];
	authUrl: string;
}

export interface IAppConfig {
	apiUrl: string;
	authUrl: string;
	invitationUrl: string;
	supportUrl: string;
	socket: string;
	downloadUrl: string;
	servicesFreeStorage: string[]
}

export interface IConfigProps {
	app: IAppConfig;
	google: IGoogleConfig;
}

export interface IConfig<TValue> {
	[id: string]: TValue;
}

class Config {

	private config: IConfig<any> = {};

	constructor(props: IConfigProps) {
		Object.entries(props).forEach(
			([key, value]) => this.config[key] = value
		);
	}

	getSection = (key: string): any => this.config[key];
	getConfig = (): IConfigProps => this.config as IConfigProps;

	getOauthClientId = (): string => this.getConfig().google.clients.base.clientId;

	getGoogleAuthUrl = (state: string = "new-account" ): string => {
		const googleConfig: IGoogleConfig = this.getConfig().google;
		const baseConfig = googleConfig.clients.base;
		const data: Object = {
			'clientId': baseConfig.clientId,
			'redirectUri': baseConfig.redirectUri,
			'responseType': 'code',
			'scope': baseConfig.scopes.join(" "),
			'state': state
		};
		let url: string = googleConfig.authUrl;
		Object.keys(data).forEach(key => {
			url = url.replace(`{${key}}`, data[key]);
		});
		return encodeURI(url);
	};

	getGoogleReConnectUrl = (): string => {
		const googleConfig: IGoogleConfig = this.getConfig().google;
		const config = googleConfig.clients.base;
		const data: Object = {
			'clientId': config.clientId,
			'redirectUri': config.reconnectUri,
			'responseType': 'code',
			'scope': config.scopes.join(" "),
			'state': 'reconnect'
		};
		let url: string = googleConfig.authUrl;
		Object.keys(data).forEach(key => {
			url = url.replace(`{${key}}`, data[key]);
		});
		return encodeURI(url);
	}

	getGooglePhotosAuthUrl = (): string => {
		const googleConfig: IGoogleConfig = this.getConfig().google;
		const config = googleConfig.clients.photos;
		const data: Object = {
			'clientId': config.clientId,
			'redirectUri': config.redirectUri,
			'responseType': 'code',
			'scope': config.scopes.join(" "),
			'state': 'connect-photos'
		};
		let url: string = googleConfig.authUrl;
		Object.keys(data).forEach(key => {
			url = url.replace(`{${key}}`, data[key]);
		});
		return encodeURI(url);
	}
}

export const AppConfig = new Config(data as IConfigProps);