import {AppConfig} from "../Config";
import {createWriteStream} from 'streamsaver';
import React, {DetailedReactHTMLElement, ReactElement} from 'react';


export const LoadScript = (
	d: Document,
	s: string,
	id: string,
	jsSrc: string,
	onLoad: () => void,
	onError: OnErrorEventHandler
): void => {
	const firstScript: HTMLScriptElement = d.getElementsByTagName(s)[0] as HTMLScriptElement;
	const script: HTMLScriptElement = d.createElement(s) as HTMLScriptElement;
	script.id = id;
	script.src = jsSrc;
	if (firstScript && firstScript.parentNode) {
		firstScript.parentNode.insertBefore(script, firstScript)
	} else {
		d.head.appendChild(script)
	}
	script.onerror = onError;
	script.onload = onLoad;
};

export const GetProcessErrorMessage = (errorCode: number): string => {
	switch (errorCode) {
		case 701:
			return "Out of storage";
		case 702:
			return "Google token expired";
		case 807:
		case 808:
			return "Google requests limit";
		default:
			return "Internal error";
	}
}

export const RemoveScript = (d: Document, id: string): void => {
	const element: HTMLScriptElement = d.getElementById(id) as HTMLScriptElement;
	if (element && element.parentNode) {
		element.parentNode.removeChild(element);
	}
};

export const getReadableSize = (bytes: number, decimals: number = 0): string => {
	const b = Number(bytes);
	if (isNaN(b) || b === 0){
		return '0 B';
	} 
	const k = 1024,
		sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		index = Math.floor(Math.log(b) / Math.log(k));
	return parseFloat((b / Math.pow(k, index)).toFixed(decimals)) + ' ' + sizes[index];
};

export const hashCode = (str: string): number => str.split('').reduce((hash, char) => char.charCodeAt(0) + ((hash << 5) - hash), 0);

export const rgbFromString = (str: string): string => {
	const hash: number = hashCode(str);
	const color = (hash & 0x00FFFFFF)
		.toString(16)
		.toUpperCase();
	return "00000".substring(0, 6 - color.length) + color;
};

export const toggle = (arr: Array<any>, element: any): Array<any> => arr.indexOf(element) === -1 ? [...arr, element] : [...arr.filter(val => val !== element)];

export const doAuth = (body: any) => fetch(AppConfig.getConfig().app.authUrl, {
	method: 'POST',
	body: JSON.stringify(body)
}).then(r => r.ok ? r.json() : new Promise((resolve, reject) =>
	r.json().then(json => reject({
		'status': r.status,
		...json
	}))
));

export const getInvitation = (code: string) => {
	const url = buildUrl(AppConfig.getConfig().app.invitationUrl, {'code': code})
	return fetch(url, {
		method: 'GET',
	}).then(r => r.ok ? r.json() : new Promise((resolve, reject) =>
		r.json().then(json => reject({
			'status': r.status,
			...json
		}))
	));
}

export const isValidInvitationCode = (str: string | null | undefined): boolean => {
	if(str === null || str === undefined) {
		return false;
	}
	const regExp = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
	return regExp.test(str) && str.length % 4 === 0;
}

export const buildUrl = (url: string, data: object) => {
	Object.keys(data).forEach(key => {
		url = url.replace(`{${key}}`, (data as any)[key]);
	});
	return url;
};

export const snakeToCamel = (s: string): string => s.replace(/([-]\w)/g, g => g[1].toUpperCase());

export const storeToLocalStorage = (key: string, value: string): void => {
	localStorage.setItem(key, value);
	window.dispatchEvent(new Event('storage'));
};
export const deleteFromLocalStorage = (key: string): void => {
	localStorage.removeItem(key);
	window.dispatchEvent(new Event('storage'));
};

export const pluralizeStr = (count: number, str: string, suffix: string = 's') => `${str}${(1 === count ? '' : suffix)}`;

export const streamDownloadFile = (url: string, defaultFileName: string) => {
	const headers = new Headers();
	const token = localStorage.getItem('token');
	headers.append('Authorization', 'Bearer ' + token);
	return fetch(url, {headers: headers, method: 'POST'} as RequestInit)
		.then((res: Response) => {
			const contentDisposition = res.headers.get('Content-Disposition');
			let fileName = defaultFileName;
			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
				if (fileNameMatch && fileNameMatch[1]) {
					fileName = fileNameMatch[1];
				}
			}
			const fileStream: WritableStream = createWriteStream(fileName);
			const writer: WritableStreamDefaultWriter = fileStream.getWriter();
			writer.releaseLock();
			return res.body?.pipeTo(fileStream);
		});
};

export const sendRequest = (url: string, body: any) => {
	const headers = new Headers();
	const token = localStorage.getItem('token');
	headers.append('Authorization', 'Bearer ' + token);
	return fetch(url, {headers: headers, method: 'POST', body: JSON.stringify(body)});
};

export const roundTo = (value: number, decimals: number) => {
	const pow: number = Math.pow(10, decimals);
	return Math.round((value + Number.EPSILON) * pow) / pow;
};
const spreadableSymbol = Symbol.isConcatSpreadable;
export const getTag = (value: any) => value === null ? value === undefined ? '[object Undefined]' : '[object Null]' : value.toString();
export const isObject = (value: any) => typeof value === 'object' && value !== null;
export const isArguments = (value: any) => isObject(value) && getTag(value) === '[object Arguments]';
export const isFlattenable = (value: any) => Array.isArray(value) || isArguments(value) || !!(value && value[spreadableSymbol]);
export const isString = (value: any) => typeof value === 'string' || (typeof value === 'object' && value != null && !Array.isArray(value) && getTag(value) === '[object String]');

/**
 * @param array The array to flatten.
 * @param depth The maximum recursion depth.
 * @param predicate The function invoked per iteration.
 * @param isStrict  Restrict to values that pass `predicate` checks.
 * @param result The initial result value.
 */
const baseFlatten = (array: Array<any>, depth: number, predicate?: (value: any) => boolean, isStrict?: boolean, result?: Array<any>) => {
	predicate || (predicate = isFlattenable);
	result || (result = []);

	if (array === null) {
		return result
	}

	for (const value of array) {
		if (depth > 0 && predicate(value)) {
			if (depth > 1) {
				// Recursively flatten arrays (susceptible to call stack limits).
				baseFlatten(value, depth - 1, predicate, isStrict, result)
			} else {
				result.push(...value)
			}
		} else if (!isStrict) {
			result[result.length] = value
		}
	}
	return result
};

export const flatten = (array: Array<any>) => {
	const length = array === null ? 0 : array.length;
	return length ? baseFlatten(array, 1) : [];
};

export const toggleElement = (array: Array<any>, element: any): Array<any> => {
	const idx = array.indexOf(element);
	if (idx === -1) {
		array.push(element);
	} else {
		array.splice(idx, 1);
	}
	return array;
};

export const isEmpty = (value: any): boolean => {
	if (value == null) { // Checks for null or undefined
		return true;
	}

	if (typeof value === 'string' || Array.isArray(value)) {
		return value.length === 0;
	}

	if (typeof value === 'object') {
		return Object.keys(value).length === 0;
	}
	return false;
};


const moneyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export const toCurrency = (money: number): string => moneyFormatter.format(money);
export const isValidEmail = (email: string) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

export const cloneElement = (element: ReactElement, props: Object) => React.cloneElement(element as DetailedReactHTMLElement<any, any>, props);

export const getObjectValue = <T>(data: any, path: string, defaultValue: T = null as unknown as T): T => {
		if (data === null || typeof data !== 'object') {
			return defaultValue;
		}
		const keys = path.split('.');
		let result: any = data;
		for (const key of keys) {
			if (result !== null && result !== undefined && typeof result === 'object' && key in result) {
				result = result[key];
			} else {
				return defaultValue;
			}
		}

		return result as T;
}
