import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getInvitation} from "../../tools/Tools";
import {AppConfig} from "../../Config";
import Loading from "../Loading";
import {Alert, Button} from "../../antd";
import styles from "../authCallback/components/DPAForm.module.scss";
import {Col, Layout, Row} from "antd";
import {IconSpin} from "../../icons/icons";
import Title from "antd/es/typography/Title";
import Error from "../authCallback/components/Error";


const InvitationCallback = () => {
	const {code} = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [invitationData, setInvitationData] = useState<any | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (code) {
			getInvitation(code).then(invite => {
				setLoading(false);
				setInvitationData({
					'message': <><b>{invite.invited_by}</b> would like you to join the cloud-to-cloud backup to a secure storage
						service at ElBackup.</>,
					'url': AppConfig.getGoogleAuthUrl(code)
				})
			}).catch(r => {
				setLoading(false);
				setError(r.error)
			});
		}
	}, [code]);

	return (
		loading ?
			<Loading col={5} title={"Preparing invitation"} showLogo={true}/>
			:
		error ?
			<Error error={error}/>
			:
			<Layout className={styles.dpaForm}>
				<Row align={"top"} justify={"center"} style={{paddingTop: '5%'}}>
					<Col xs={22} sm={16} md={14} lg={10} xl={8} xxl={7}>
						<div className={styles.logo}>
							<IconSpin style={{fontSize: 140}}/>
						</div>
						<Title className={styles.h3} level={3}>Invitation to ElBackup</Title>
						<Alert message={invitationData.message} type={"info"}/>
						<div className={styles.buttons}>
							<Button type={"primary"} size={"large"} onClick={() => {
								window.location.href = invitationData.url
							}}>Apply Invitation</Button>
						</div>
					</Col>
				</Row>
			</Layout>

	)
};

export default InvitationCallback;
