import React from "react";
import {IconCalendar, IconContacts, IconDrive, IconGmail, IconPhotos, IconPicasa, IconTasks} from "../icons/icons";


export enum Services {
	SERVICE_GOOGLE_MAIL = "google-mail",
	SERVICE_GOOGLE_CALENDAR = "google-calendars",
	SERVICE_GOOGLE_DRIVE = "google-docs",
	SERVICE_GOOGLE_PHOTOS = "google-photos",
	SERVICE_GOOGLE_CONTACTS = "google-contacts",
	SERVICE_GOOGLE_PEOPLE = "google-people",
	SERVICE_GOOGLE_TASKS = "google-tasks",
	SERVICE_GOOGLE_PICASA = "google-picasa",
}

export const ServicesList: string[] = [
	Services.SERVICE_GOOGLE_MAIL,
	Services.SERVICE_GOOGLE_CALENDAR,
	Services.SERVICE_GOOGLE_DRIVE,
	Services.SERVICE_GOOGLE_PHOTOS,
	Services.SERVICE_GOOGLE_CONTACTS,
	Services.SERVICE_GOOGLE_TASKS,
	Services.SERVICE_GOOGLE_PICASA,
	Services.SERVICE_GOOGLE_PEOPLE
];

export const ServiceColors = {
	[Services.SERVICE_GOOGLE_MAIL]: "#e24c40",
	[Services.SERVICE_GOOGLE_CALENDAR]: "#FFCF63",
	[Services.SERVICE_GOOGLE_DRIVE]: "#33a852",
	[Services.SERVICE_GOOGLE_PICASA]: "#9b479f",
	[Services.SERVICE_GOOGLE_PHOTOS]: "#ee7701",
	[Services.SERVICE_GOOGLE_CONTACTS]: "#427fed",
	[Services.SERVICE_GOOGLE_PEOPLE]: "#427fed",
	[Services.SERVICE_GOOGLE_TASKS]: "#7AC7FF",
	'free': "#c4c4c4"
};

export const ServiceIcon = {
	[Services.SERVICE_GOOGLE_MAIL]: <IconGmail/>,
	[Services.SERVICE_GOOGLE_CALENDAR]: <IconCalendar/>,
	[Services.SERVICE_GOOGLE_DRIVE]: <IconDrive/>,
	[Services.SERVICE_GOOGLE_PICASA]: <IconPicasa/>,
	[Services.SERVICE_GOOGLE_CONTACTS]: <IconContacts/>,
	[Services.SERVICE_GOOGLE_PEOPLE]: <IconContacts/>,
	[Services.SERVICE_GOOGLE_TASKS]: <IconTasks/>,
	[Services.SERVICE_GOOGLE_PHOTOS]: <IconPhotos/>,
};

export const ServiceTitle = {
	[Services.SERVICE_GOOGLE_MAIL]: 'Google Mail',
	[Services.SERVICE_GOOGLE_CALENDAR]: 'Google Calendar',
	[Services.SERVICE_GOOGLE_DRIVE]: 'Google Drive',
	[Services.SERVICE_GOOGLE_PICASA]: 'Google Picasa',
	[Services.SERVICE_GOOGLE_CONTACTS]: 'Google Contacts',
	[Services.SERVICE_GOOGLE_PEOPLE]: 'Google People',
	[Services.SERVICE_GOOGLE_TASKS]: 'Google Tasks',
	[Services.SERVICE_GOOGLE_PHOTOS]: 'Google Photos',
};

export const ServiceSearch = {
	[Services.SERVICE_GOOGLE_MAIL]: true,
	[Services.SERVICE_GOOGLE_CALENDAR]: true,
	[Services.SERVICE_GOOGLE_DRIVE]: true,
	[Services.SERVICE_GOOGLE_PICASA]: false,
	[Services.SERVICE_GOOGLE_CONTACTS]: false,
	[Services.SERVICE_GOOGLE_PEOPLE]: true,
	[Services.SERVICE_GOOGLE_TASKS]: true,
	[Services.SERVICE_GOOGLE_PHOTOS]: true,
};